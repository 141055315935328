<script>
	export let checked;
	export let color = 'bg-ruby-500';
	export let options = {
		direction: 'column',
		id: 'B'
	};
</script>

<label
	for="toggle{options.id || 'B'}"
	class="flex {options.direction === 'row' ? 'gap-2' : 'flex-col'} cursor-pointer"
>
	<slot />
	<!-- toggle -->
	<div class="relative">
		<!-- input -->
		<input bind:checked type="checkbox" id="toggle{options.id || 'B'}" class="sr-only" />
		<!-- line -->
		<div
			class="block {checked
				? 'bg-opacity-30 ' + (color || 'bg-ruby-500')
				: ''} bg-gray-600 w-9 h-4 rounded-full transition-colors"
		/>
		<!-- dot -->
		<div
			class="dot {checked
				? 'translate-x-[160%] ' + (color || 'bg-ruby-500')
				: ''} absolute left-0.5 top-0.5 bg-black w-3 h-3 rounded-full transition"
		/>
	</div>
	<!-- label -->
</label>

<style lang="postcss">
	/* Toggle B */
</style>
