<script>
	import SubscriptionCards from './SubscriptionCards.svelte';
	import Navigation from '../sections/Navigation.svelte';
	import Survey from './Survey.svelte';
	import Modal from './Modal.svelte';
	import Referral from './Referral.svelte';
	import { goto } from '$app/navigation';
	import { onMount, onDestroy } from 'svelte';
	import { NotPaid, Platform, IsDev, Path, UserEmail, Version } from '$lib/store.js';

	export let dismissable = false;
	let dismissed = false;
	let karmaThresholds = {
		tracking: 0,
		profiling: 10,
		targeting: 25,
		socialising: 50,
		compromising: 50
	};
	let mounted;
	onMount(() => {
		console.log('mounted paywall', $NotPaid, $Platform, $UserEmail, $Version);
		mounted = true;
	});
	$: if (mounted && typeof document !== 'undefined') {
		let content = document.getElementById('content');
		if ($NotPaid) {
			paywallSeen();
			if (content) content.style.filter = 'blur(3px)';
		} else {
			if (content) content.style.filter = 'none';
		}
	}
	onDestroy(() => {
		if (typeof document === 'undefined') return;
		let content = document.getElementById('content');
		if (content) content.style.filter = 'none';
	});
	export let hasPaid;
	$: hasPaid = !$NotPaid;
	function paywallSeen() {
		if (!$IsDev)
			window.analytics?.track?.('Paywall Seen', {
				platform: $Platform,
				duration: '90 min',
				environment: $IsDev ? 'dev' : 'production',
				email: $UserEmail
			});
	}
	export let topLevel = true;
	export let subscribeText = 'for more';
	let referral;
</script>

{#if $NotPaid && !(dismissed && dismissable) && !$Path.includes('profile') & !$Path.includes('onboard')}
	<Modal
		show={!dismissed}
		allowClose={dismissable}
		bgClasses={'bg-opacity-20'}
		options={{
			topLevel,
			cssText: `display:flex;flex-direction:column;align-items:center;width:max-content;border-radius:2.5rem;box-shadow: 0 4px 800px 100px
    #5a8fcf24, 0 2px 400px -10px #5a8fcf24, 0 2px 8px -1px #3a5fff24`
		}}
		on:close={() => (dismissed = true)}
	>
		<!-- <a
			class="absolute block left-3 top-1 cursor-pointer bg-transparent text-white justify-end shadow-none p-1 m-1 ml-0"
			on:click|preventDefault={() => goto('/')}
			href="/"
		>	
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-6 
		w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M7 16l-4-4m0 0l4-4m-4 4h18"
				/>
			</svg>
			<span class="blur-sm" />
		</a> -->
		<Navigation />
		<div class="text-2xl font-bold text-center -mb-4">Subscribe {subscribeText}!</div>
		<slot />
		<SubscriptionCards />
		<div class="-mt-8 text-center">
			<button on:click={() => (referral = true)} class="bg-transparent hover:bg-black"
				>or refer a friend for 1 month free</button
			>
		</div>
		<div class="text-xs text-center px-16">
			<div>
				<a
					style="color:#1982FC;"
					href="{!$IsDev
						? $Version?.platform != 'darwin' || $Platform !== 'macos'
							? 'mailto:mike@' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com')
							: 'sms:+447576358357'
						: 'sms:+447576358357'}?&body={encodeURI(
						`Hey Mike, it's ${
							($UserEmail || '')?.replace('@', ' @ ') || 'a new user'
						}, I can't afford ${
							$Version.app === 'omnipilot' ||
							(typeof window !== 'undefined' && window?.location?.href.includes('omnipilot'))
								? 'Omnipilot'
								: 'Magicflow'
						} :(`
					)}"
					target="_blank">Click to message us if you can't afford it.</a
				>
			</div>
			<div>We're always happy to help students, founders and jobseekers!</div>
		</div>
		{#if referral}
			<Modal
				bind:show={referral}
				options={{
					classes:
						'absolute w-full h-full top-0 left-0 bg-black rounded-2xl flex flex-col items-center justify-center'
				}}
			>
				<Referral />
			</Modal>
		{/if}
	</Modal>
{/if}
